import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import LazyLoad from 'react-lazyload'

import * as common from '@rushplay/common'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import * as Session from './session'
import * as configuration from './configuration'
import { AppVersion } from './app-version'
import { ClientType } from './constants'
import { HtmlContent } from './html-content'
import { PaymentProviderImages } from './payment-provider-images'
import { TobiqueSeal } from './tobique-seal'
import { useCookieConsent } from './use-cookie-consent'
import { useGameInfoUrlQuery } from './use-game-info-url-query'

const LinkWrapper = styled.div`
  ${css({
    p: 1,
  })}
  opacity: 0.5;
  transition: opacity 75ms ease-in;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`

export function Footer() {
  const i18n = useI18n()
  const gameInfoQuery = useGameInfoUrlQuery()
  const clientType = ReactRedux.useSelector(state =>
    configuration.getClientType(state.configuration)
  )
  const brand = ReactRedux.useSelector(state =>
    configuration.getBrand(state.configuration)
  )
  const cookieConsentEnabled = useCookieConsent()
  const isMobile = R.equals(ClientType.MOBILE_BROWSER, clientType)
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )
  const license = ReactRedux.useSelector(state =>
    Jurisdiction.getLicense(state.jurisdiction)
  )

  /**
   * Hides the footer:
   * - When playing any game on mobile
   * - You're on the Experts Area route
   */

  if (
    isMobile &&
    ((R.includes(gameInfoQuery.referrer, ['live-casino']) && authenticated) ||
      R.includes(gameInfoQuery.referrer, ['casino']))
  ) {
    return null
  }

  return (
    <common.Box
      display="flex"
      backgroundColor="footer-background"
      padding={[1, 2]}
      flexDirection="column"
      alignItems="center"
      as="footer"
    >
      <common.Box
        as="nav"
        color="g-text"
        display="flex"
        flexWrap="wrap"
        fontSize={1}
        justifyContent="center"
        pt={0}
      >
        <ReactRouter.Link
          to="/terms-and-conditions"
          data-testid="footer.link.terms"
        >
          <LinkWrapper>
            {i18n.translate('footer.terms-and-conditions')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/about" data-testid="footer.link.about">
          <LinkWrapper>{i18n.translate('footer.about-us')}</LinkWrapper>
        </ReactRouter.Link>
        {cookieConsentEnabled && (
          <ReactRouter.Link
            to="/cookie-policy"
            data-testid="footer.link.cookie-policy"
          >
            <LinkWrapper>{i18n.translate('footer.cookie-policy')}</LinkWrapper>
          </ReactRouter.Link>
        )}
        <ReactRouter.Link to="/faq" data-testid="footer.link.faq">
          <LinkWrapper>{i18n.translate('footer.faq')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/privacy-policy"
          data-testid="footer.link.privacy-policy"
        >
          <LinkWrapper>{i18n.translate('footer.privacy-policy')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/responsible-gaming"
          data-testid="footer.link.responsible-gaming"
        >
          <LinkWrapper>
            {i18n.translate('footer.responsible-gaming')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/security" data-testid="footer.link.security">
          <LinkWrapper>{i18n.translate('footer.security')}</LinkWrapper>
        </ReactRouter.Link>
        {/* I'm not removing it compleately becouse we will probably have to add it back later */}
        {/* <a
          href="https://heropartners.io/"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="footer.link.affiliate"
        >
          <LinkWrapper>{i18n.translate('footer.affiliate')}</LinkWrapper>
        </a> */}
      </common.Box>
      <PaymentProviderImages padding="1" />
      <common.Box
        fontSize={1}
        color="g-text"
        opacity="0.5"
        pt={2}
        textAlign="center"
        mx="auto"
        maxWidth="700px"
      >
        <HtmlContent
          as="small"
          html={{
            __html: i18n.translate('footer.about-text'),
          }}
        />
      </common.Box>
      <common.Box pt={0} display="flex" flexWrap="wrap" justifyContent="center">
        <common.Space p={0}>
          {brand === 'boom24' ? (
            <TobiqueSeal />
          ) : (
            <a
              href="https://certificates.gamingcommission.ca/KGCCertificates.aspx?id=2d818386-b504-44b5-9455-0f361c034ccd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoad
                once
                offset={100}
                height="34px"
                placeholder={
                  <common.Text fontSize={1}>{license} license</common.Text>
                }
              >
                <img
                  src={i18n.translate('license-logo')}
                  alt={license}
                  height="34"
                />
              </LazyLoad>
            </a>
          )}
        </common.Space>
        <common.Space p={0}>
          <a
            href="https://www.gamblingtherapy.org/en"
            target="_blank"
            data-testid="footer.gambling-therapy.link"
            rel="noopener noreferrer"
          >
            <LazyLoad
              once
              offset={100}
              height="34px"
              placeholder={
                <common.Text fontSize={1}>Gambling Therapy</common.Text>
              }
            >
              <img
                src={i18n.translate('gamcare-logo')}
                alt="Gambling Therapy"
                height="34"
                data-testid="footer.gamcare-logo"
              />
            </LazyLoad>
          </a>
        </common.Space>
        <common.Space p={0}>
          <ReactRouter.Link
            to="/responsible-gaming"
            data-testid="footer.responsible-gaming.link"
          >
            <LazyLoad
              once
              offset={100}
              height="34px"
              placeholder={<common.Text fontSize={1}>18+</common.Text>}
            >
              <img
                src={i18n.translate('18-plus')}
                alt="18+"
                height="34"
                data-testid="footer.18-plus-logo"
              />
            </LazyLoad>
          </ReactRouter.Link>
        </common.Space>
      </common.Box>
      <AppVersion />
    </common.Box>
  )
}

// for @loadable/components
export default Footer
