import * as React from 'react'
import { Helmet } from 'react-helmet-async'

export function TobiqueSeal() {
  //TODO: get the seal id from BE
  const sealId = '8159740d-d8ee-4557-9f2e-f4c1e782e85f'

  React.useEffect(() => {
    if (window.dlag_8159740d_d8ee_4557_9f2e_f4c1e782e85f) {
      window?.dlag_8159740d_d8ee_4557_9f2e_f4c1e782e85f.init()
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <script
          type="text/javascript"
          src={`https://${sealId}.seals.dlagglobal.com/dlag-seal.js`}
        />
      </Helmet>
      <div
        id={`dlag-${sealId}`}
        data-dlag-seal-id={sealId}
        data-dlag-image-size="40"
        data-dlag-image-type="basic-small"
      ></div>
    </React.Fragment>
  )
}
